import React from 'react'
import './../../../Footer.css'
import WeActLogo from '../../../weact_logo.png'
const WeActFooter = () => {
  return (
    <div className='footer weact'>
      <img src={WeActLogo} alt="" />
      <div className='footercontent weact'>
        <p></p>
        <h5>For More information, Contact:</h5>
        <br />
        <br />
        <b>Jazz Hooks, </b><br />
        <i> Workforce Development Training Coordinator <u>jazz.hooks@weact.org</u></i>
        <p className='copyright weact'>Copyright © {new Date().getFullYear()} WE ACT. All rights reserved</p>
      </div>
    </div>
  )
}

export default WeActFooter
// For class information, contact:

// Charles Calloway
// Director of Workforce Development
// charles@weact.org